<template>
  <div id="page-user-list">
    <div class="pt-10">
      <vx-card v-if="directorDetails" class="mb-base">
        <div class="flex flex-wrap justify-between items-center">
          <div class="vx-breadcrumb md:block hidden">
            <ul class="flex flex-wrap items-center">
              <li class="inline-flex item-center">
                <router-link :to="{ name: 'director-learning-centre-list' }"
                  >Manage</router-link
                >
                <span class="breadcrumb-separator mx-2">
                  <span class="feather-icon select-none relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-chevrons-right"
                    >
                      <polyline points="13 17 18 12 13 7" />
                    </svg>
                  </span>
                </span>
              </li>
              <li class="inline-flex item-center">
                <router-link :to="{ name: 'director-learning-centre-list' }"
                  >Regional Directors</router-link
                >
                <span class="breadcrumb-separator mx-2">
                  <span class="feather-icon select-none relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-chevrons-right"
                    >
                      <polyline points="13 17 18 12 13 7" />
                    </svg>
                  </span>
                </span>
              </li>
              <li class="inline-flex item-center">
                <a href="javascript:void(0)" class>{{
                  directorDetails.fullName
                }}</a>
              </li>
            </ul>
          </div>
          <div class="flex my-5 mt-0">
            <div class="ml-auto">
              <vs-button @click="editDirector(directorDetails)">Edit</vs-button>
            </div>
          </div>
        </div>
        <h2>{{ directorDetails.fullName }}</h2>
        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
          class="admin-detail-wrap"
        >
          <vs-col vs-xs="12" vs-sm="7" vs-lg="6" class="py-10">
            <div class="w-full p-5">
              <label class="font-semibold text-dark"
                >Regional Director Name</label
              >
              <p class="text-dark">{{ directorDetails.fullName }}</p>
            </div>
            <div class="w-full p-5">
              <label class="font-semibold text-dark">Phone Number</label>
              <p class="text-dark">
                {{ directorDetails.mobileNumber }}
              </p>
            </div>
            <div class="w-full p-5">
              <label class="font-semibold text-dark">Email</label>
              <p class="text-dark">{{ directorDetails.email }}</p>
            </div>
            <div class="w-full p-5">
              <label class="font-semibold text-dark">Country</label>
              <p class="text-dark">{{ directorDetails.address.country || "" }}</p>
            </div>
          </vs-col>
          <vs-col vs-xs="12" vs-sm="7" vs-lg="6" class="py-10">
            <div class="w-full p-5">
              <label class="font-semibold text-dark">State</label>
              <p class="text-dark">{{ directorDetails.address.state || ""}}</p>
            </div>
            <div class="w-full p-5">
              <label class="font-semibold text-dark">Street Address</label>
              <p class="text-dark">
                {{
                  "undefined" != typeof directorDetails.address
                    ? directorDetails.address.displayAddress
                    : ""
                }}
              </p>
            </div>
            <div class="w-full p-5">
              <label class="font-semibold text-dark">Town/City</label>
              <p class="text-dark">
                {{
                  "undefined" != typeof directorDetails.address
                    ? directorDetails.address.suburb
                    : ""
                }}
              </p>
            </div>
            <div class="w-full p-5">
              <label class="font-semibold text-dark">Postcode</label>
              <p class="text-dark">
                {{
                  "undefined" != typeof directorDetails.address
                    ? directorDetails.address.postCode
                    : ""
                }}
              </p>
            </div>
          </vs-col>
        </vs-row>
      </vx-card>

      <vx-card class="mb-base directors-card">
        <vs-table
          ref="table"
          :sst="true"
          :total="totalDocs"
          :max-items="dataTableParams.limit"
          :data="learningCentreData"
          @search="handleSearch"
          :noDataText="noDataText"
        >
          <div slot="header" class="w-full mb-8">
            <div class="flex flex-wrap items-center">
              <div class="w-full md:w-3/4">
                <div class="card-title">
                  <h2>Assigned Centres</h2>
                </div>
              </div>
            </div>
            <div class="flex flex-wrap items-center mt-10 lg:mt-0">
              <div class="w-full lg:w-3/4 flex flex-wrap header-table-row">
                <div class="w-full md:w-1/2">
                  <vs-input
                    class="w-full search-input"
                    icon-pack="feather"
                    icon="icon-search"
                    v-model="searchQuery"
                    placeholder="Search..."
                  />
                </div>
                <div class="w-full md:w-1/4 md:pl-10">
                  <div class="select-wrapper md:mt-0">
                    <!-- <label class="block mb-2">Country</label> -->
                    <v-select
                      :options="countryOptions"
                      :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="countryFilter"
                    >
                      <template #header>
                        <label for class="label">Country</label>
                      </template>
                      <template #open-indicator="{ attributes }">
                        <span v-bind="attributes">
                          <vs-icon icon="arrow_drop_down"></vs-icon>
                        </span>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="w-full md:w-1/4 md:pl-10">
                  <div class="select-wrapper md:mt-0">
                    <v-select
                      :options="stateOptions"
                      :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="stateFilter"
                      class="mb-4 lg:mb-0"
                    >
                      <template #header>
                        <label for class="label">Region</label>
                      </template>
                      <template #open-indicator="{ attributes }">
                        <span v-bind="attributes">
                          <vs-icon icon="arrow_drop_down"></vs-icon>
                        </span>
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template slot="thead">
            <vs-th>Name</vs-th>
            <vs-th>Country</vs-th>
            <vs-th>Region</vs-th>
            <vs-th>No. Regional Directors</vs-th>
            <vs-th>No. Admin Team</vs-th>
            <vs-th>No. Teachers</vs-th>
            <vs-th>No. Children</vs-th>
            <vs-th>Action</vs-th>
            <vs-th>&nbsp;</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr
              :data="tr"
              :key="indextr"
              v-for="(tr, indextr) in data"
            >
              <template>
                <vs-td :data="tr.name">
                  {{ tr.name | capitalize }}
                </vs-td>

                <vs-td :data="tr.country ? tr.country[0] : ''">
                  {{ tr.country ? tr.country[0] : "" | capitalize }}
                </vs-td>
                <vs-td :data="tr.address.state">
                  {{ tr.address.state | capitalize }}
                </vs-td>
                <vs-td :data="tr.regionalDirectors">
                  {{ tr.regionalDirectors.length }}
                </vs-td>
                <vs-td :data="tr.noAdmins">
                  {{ tr.noAdmins }}
                </vs-td>
                <vs-td :data="tr.noTeachers">
                  {{ tr.noTeachers }}
                </vs-td>
                <vs-td :data="tr.noChildren">
                  {{ tr.noChildren }}
                </vs-td>
                <vs-td class="whitespace-no-wrap">
                  <a
                    href="#"
                    class="nav-link d-flex align-items-center active"
                    @click.stop="viewHandler(tr._id)"
                  >
                    <feather-icon
                      icon="EyeIcon"
                      svgClasses="w-5 h-5 hover:text-primary stroke-current icon-eye"
                    />
                  </a>
                </vs-td>
                <vs-td :data="tr.noChildren">
                  <a href="javascript::void()" @click="removeCenter(tr)">Remove</a>
                </vs-td>
              </template>
            </vs-tr>
          </template>
        </vs-table>
      </vx-card>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

export default {
  components: {
    vSelect
  },
  data() {
    return {
      centerSidebarData: {},
      directorDetails: {},

      totalDocs: 0,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "asc",
        limit: 100,
        page: 1,
        country: "all",
        state: "all"
      },
      learningCentreData: [],
      countryOptions: [],
      countryFilter: { label: "All", value: "all" },
      stateOptions: [],
      stateFilter: { label: "All", value: "all" },
      serverResponded: false,
      searchQuery: "",
      awaitingSearch: false,
      sidebarData: {},
      noDataText: "Currently no learning center available"
    };
  },

  methods: {
    ...mapActions("center", [
      "getDirectorLearningCentreList",
      "getDirectorAllCountries",
    ]),

    ...mapActions("director", ["getRegionalDirectorDetail",
    "getRegionalDirectorLearningCentreList","removeCenterFromRegionalDirector"]),

    async getLearningCentreData() {
      this.dataTableParams.country =
        this.countryFilter.value == "all" ? "" : this.countryFilter.value;
      this.dataTableParams.state =
        this.stateFilter.value == "all" ? "" : this.stateFilter.value;
      this.dataTableParams.search = this.searchQuery;
      this.dataTableParams.directorId = this.$route.params.id;
      this.$vs.loading();
      console.log(this.dataTableParams,"this.dataTableParams");
      await this.getRegionalDirectorLearningCentreList(this.dataTableParams)
        .then(async res => {
          let centers = await res.data.data;

          this.learningCentreData = centers.docs;
          this.totalDocs = centers.pagination.total;
          this.page = centers.pagination.page;
          this.serverResponded = true;

          if (this.learningCentreData.length == 0) {
            this.noDataText =
              "Cannot find Learning Center with search text: '" +
              this.searchQuery +
              "'";
          }
          this.$vs.loading.close();
        })
        .catch(err => {
          this.$vs.loading.close();
          if (err.response.status === 403) {
            Swal.fire({
              title: "No Access",
              html: `
                  <div>
                    You dont have permission to visit this page. Please upgrade your package.
                  </div>`,
              icon: "warning",
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33"
            }).then(result => {
              this.$router.push("/director/upgrade-package");
            });
          }
        });
    },
    handleSearch(searching) {
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getLearningCentreData();
    },
    viewHandler(id) {
      this.$router.push({
        name: "director-learning-centre-view",
        params: { id: id }
      });
    },

    async getRegionalDirectorData(id) {
      this.$vs.loading();
      await this.getRegionalDirectorDetail(id)
        .then(async res => {
          if (res.status === 200) {
            this.directorDetails = await res.data.data;
          }
          this.$vs.loading.close();
        })
        .catch(err => {
          this.$vs.loading.close();
          if (err.response.status === 403) {
            Swal.fire({
              title: "No Access",
              html: `
                  <div>
                    You dont have permission to visit this page. Please upgrade your package.
                  </div>`,
              icon: "warning",
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33"
            }).then(result => {
              //this.$router.push('/director/upgrade-package');
            });
          }
        });
    },

    editDirector(data) {
      this.$router.push({
        name: "regional-director-edit",
        params: { id: data._id }
      });
    },


    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color
      });
    },
    removeCenter(center) {
      Swal.fire({
        title: "Are you sure you want to remove "+ this.directorDetails.fullName +"from "+center.name+"?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, remove it!"
      }).then(result => {
        if (result.value) {
          this.acceptDeleteAlert(center._id);
        }
      });
    },
    acceptDeleteAlert(id) {
      this.$vs.loading();
      let data = {
        centerId:id,
        regionalDirectorId:this.$route.params.id
      }
      this.removeCenterFromRegionalDirector(data)
        .then(res => {
          if (res.status === 200) {
            const centerIndex = this.learningCentreData.findIndex((u) => u._id == id)
            this.learningCentreData.splice(centerIndex, 1)
            this.$vs.loading.close();
            this.showMessage(
              "Success",
              "Center deleted successfully.",
              "success"
            );
          }
        })
        .catch(err => {
          this.$vs.loading.close();
        });
    },
  },
  computed: {

  },
  watch: {
    "dataTableParams.limit": function(newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getLearningCentreData();
      }
    },

    countryFilter(obj) {
      this.stateOptions = obj.states;
      this.stateFilter = { label: "All", value: "all" };
      this.getLearningCentreData();
    },
    stateFilter(obj) {
      this.getLearningCentreData();
    },
    searchQuery: function(val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.getLearningCentreData();
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
  },

  async created() {
    let id = this.$route.params.id;
    await this.getRegionalDirectorData(id);
    await this.getLearningCentreData();
    await this.getDirectorAllCountries()
      .then(async res => {
        this.countryOptions = await this.$store.state.center.countryOptions;
      });
  }
};
</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}

.deleteBtn {
  background-color: white;
  color: black;
  border: 2px solid #f44336;
}
</style>
